import { PROXY_TREE, VALUE } from 'proxy-state-tree'

export type StatemachineDefinition<States extends string> = {
  initial: States,
  states: {
    [State in States]: Array<States>
  }
}

export type Statemachine<States extends string> = {
  current: States
  reset: () => void
} & {
  [State in States]: <T extends () => any>(entry?: T, exit?: () => void) => T extends () => infer U ? U : void
}

const CURRENT_EXIT = Symbol('CURRENT_EXIT')
const INITIAL_STATE = Symbol('INITIAL_STATE')

class StateMachine<States extends string>  {
  current: States
  private [CURRENT_EXIT]: (() => void) | undefined
  private [INITIAL_STATE]: States
  constructor(definition: StatemachineDefinition<States>) {
    this[INITIAL_STATE] = definition.initial
    this.current = definition.initial

    Object.keys(definition.states).reduce((aggr, key) => {
      aggr[key] = function (entry, exit) {
        if (definition.states[this.current].includes(key as any)) {
          const tree = (this[PROXY_TREE].master.mutationTree || this[PROXY_TREE])

          tree.enableMutations()
          if (this[CURRENT_EXIT]) this[CURRENT_EXIT]()
          this[VALUE][CURRENT_EXIT] = exit
          this.current = key as any
          const result = entry && entry()
          tree.blockMutations()

          return result
        }
      }
  
      return aggr
    }, this)
  }
  reset() {
    const exit = this[CURRENT_EXIT]
    if (typeof exit === 'function') {
      exit()
      this[VALUE][CURRENT_EXIT] = undefined
    }
    this.current = this[INITIAL_STATE]
  }
}

export function statemachine<States extends string>(definition: StatemachineDefinition<States>): Statemachine<States> {
  return new StateMachine(definition) as any
}
