import isPlainObject from 'is-plain-obj'
import { IMutation, IS_PROXY, VALUE } from 'proxy-state-tree'

import { Derived } from './derived'
import { IState } from './types'

export const IS_TEST = process.env.NODE_ENV === 'test'
export const IS_OPERATOR = Symbol('operator')
export const ORIGINAL_ACTIONS = Symbol('origina_actions')
export const EXECUTION = Symbol('execution')

export const MODE_DEFAULT = Symbol('MODE_DEFAULT')
export const MODE_TEST = Symbol('MODE_TEST')
export const MODE_SSR = Symbol('MODE_SSR')

export class MockedEventEmitter {
  emit() {}
  emitAsync() {}
  on() {}
  once() {}
  addListener() {}
}

export const json = <T>(obj: T): T => {
  return deepCopy(obj && obj[IS_PROXY] ? obj[VALUE] : obj)
}
  

export function isPromise(maybePromise: any) {
  return (
    maybePromise instanceof Promise ||
    (maybePromise &&
      typeof maybePromise.then === 'function' &&
      typeof maybePromise.catch === 'function')
  )
}

export function processState(state: {}, derivedReferences?: any[]) {
  return Object.keys(state).reduce((aggr, key) => {
    if (key === '__esModule') {
      return aggr
    }
    const originalDescriptor = Object.getOwnPropertyDescriptor(state, key)

    if (originalDescriptor && 'get' in originalDescriptor) {
      Object.defineProperty(aggr, key, originalDescriptor as any)

      return aggr
    }

    const value = state[key]

    if (isPlainObject(value)) {
      aggr[key] = processState(value, derivedReferences)
    } else if (typeof value === 'function') {
      aggr[key] = new Derived(value)

      if (derivedReferences) {
        derivedReferences.push(aggr[key])
      }
    } else {
      Object.defineProperty(aggr, key, originalDescriptor as any)
    }

    return aggr
  }, {})
}

export function getFunctionName(func: Function) {
  return func.name || (func as any).displayName || ''
}

export function deepCopy(obj) {
  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce((aggr: any, key) => {
      if (key === '__esModule') {
        return aggr
      }

      const originalDescriptor = Object.getOwnPropertyDescriptor(obj, key)
      const isAGetter = originalDescriptor && 'get' in originalDescriptor
      const value = obj[key]

      if (isAGetter) {
        Object.defineProperty(aggr, key, originalDescriptor as any)
      } else {
        aggr[key] = deepCopy(value)
      }

      return aggr
    }, {})
  } else if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item))
  }

  return obj
}

export function mergeState(originState, oldState, nextState) {
  function merge(origin, old, next) {
    if (isPlainObject(old) && isPlainObject(next)) {
      const newBranch = {}
      const keys = Object.keys(old).concat(Object.keys(next))

      for (let key of keys) {
        newBranch[key] = merge(origin[key], old[key], next[key])
      }

      return newBranch
    }

    if (typeof next === 'function') {
      return next
    }

    // We return the existing array, as arrays are typically
    // mutated, not set with new values as initial state
    if (Array.isArray(old) && Array.isArray(next)) {
      return old
    }

    // If we have changed a state from origin, keep that
    // changed state
    if (next === origin && old !== origin) {
      return old
    }

    return next
  }

  return merge(originState, oldState, nextState)
}

export function getActionPaths(actions = {}, currentPath: string[] = []) {
  return Object.keys(actions).reduce<string[]>((aggr, key) => {
    if (typeof actions[key] === 'function') {
      return aggr.concat(currentPath.concat(key).join('.'))
    }

    return aggr.concat(getActionPaths(actions[key], currentPath.concat(key)))
  }, [])
}

export function createActionsProxy(actions, cb) {
  return new Proxy(actions, {
    get(target, prop) {
      if (prop === ORIGINAL_ACTIONS) {
        return actions
      }

      if (typeof target[prop] === 'function') {
        return cb(target[prop])
      }

      if (!target[prop]) {
        return undefined
      }

      return createActionsProxy(target[prop], cb)
    },
  })
}
